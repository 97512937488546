import { useState } from "react";

export function useShouldRenderWellnessPlanSuccessPage() {
  const [shouldRenderSuccessPage, setShouldRenderSuccessPage] =
    useState<boolean>(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const comingFromStripe =
        searchParams.get("wp") && searchParams.get("identityId");
      return Boolean(comingFromStripe);
    });

  return {
    shouldRenderSuccessPage: shouldRenderSuccessPage,
    onClose: () => setShouldRenderSuccessPage(false),
  };
}
